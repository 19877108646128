import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_TicketRapportDetailTables = _resolveComponent("TicketRapportDetailTables")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:visible', $event))),
    visible: _ctx.visible,
    header: _ctx.$t('ticket.details'),
    style: {"min-width":"50vw"},
    draggable: ""
  }, {
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            mode: "indeterminate",
            style: {"height":"5px"}
          }))
        : _createCommentVNode("", true),
      (!!_ctx.ticket)
        ? (_openBlock(), _createBlock(_component_TicketRapportDetailTables, {
            key: 1,
            loading: _ctx.loading,
            ticket: _ctx.ticket
          }, null, 8, ["loading", "ticket"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}