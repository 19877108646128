
import { defineComponent } from "vue";
import { QueryTicketGetArgs, Ticket } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useQuery, useResult } from "@vue/apollo-composable";
import { PAYMENT_RECEIPT_FIELDS } from "@/graphql/payment-receipt/payment-receipt";
import { TICKET_FIELDS } from "@/graphql/ticket/ticket";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import TicketRapportDetailTables from "./TicketRapportDetailTables.vue";

type Data = {
  ticketGet: Ticket;
};

const QUERY = gql`
    query TicketGet($number: Int, $id: String){
        ticketGet(number: $number, id: $id) {
            ${TICKET_FIELDS}
            sequence {
              id
              number
              terminal {
                id
                name
              }
            }
            movements {
               ${MOVEMENT_FIELDS}
                product {
                  id
                  name
                  reference
                }
            }
            payments {
              ${PAYMENT_RECEIPT_FIELDS}
            }
            customer {
              id
              name
            }
          }
        }

`;

export default defineComponent({
  name: "TicketGet",
  props: ["number", "id", "visible"],
  emits: ["update:visible"],
  components: { TicketRapportDetailTables },
  setup(props) {
    const { loading, result } = useQuery<Data, QueryTicketGetArgs>(
      QUERY,
      { number: props.number, id: props.id },
      { fetchPolicy: "no-cache" }
    );
    const ticket = useResult<Data, Ticket, Ticket>(
      result,
      null,
      (res) => res.ticketGet
    );

    return {
      loading,
      ticket,
    };
  },
});
